import parse from 'html-react-parser'
import {useEffect, useState} from "react";

function Content({text, title = null, subtitle= null}){
    const [resultText, setResultText] = useState('');

    useEffect(()=>{
        let txtHtml = document.createElement('div');
        txtHtml.innerHTML = text;
        let result = '';

        for (let i = 0; i < txtHtml.children.length; i++) {
            let it = txtHtml.children[i];
            if(it.tagName.toLowerCase() === 'table'){
                let dataTitles = [];
                if(it.getElementsByTagName('th').length > 0){
                    let firstRow = it.querySelectorAll('th');
                    for(let j = 0; j < firstRow.length; j++){
                        dataTitles.push(firstRow[j].innerText.trim());
                    }
                }else{
                    console.log(it.getElementsByTagName('tr')[0]);
                    it.getElementsByTagName('tr')[0].classList.add('desk-only');
                    let firstRow = it.getElementsByTagName('tr')[0].querySelectorAll('td');
                    for(let j = 0; j < firstRow.length; j++){
                        dataTitles.push(firstRow[j].innerText.trim());
                    }
                }

                let tdRows = it.querySelectorAll('tr');
                for(let k = 0; k < tdRows.length; k++){
                    if((it.getElementsByTagName('th').length > 0 && k >= 0) || (it.getElementsByTagName('th').length === 0 && k >= 1)){
                        let tdCols = tdRows[k].querySelectorAll('td');
                        for (let l = 0; l < tdCols.length; l++){
                            tdCols[l].dataset['title'] = dataTitles[l];
                        }
                    }
                }

                result += '<table>' + it.innerHTML + '</table>';

            }else{
                result += it.outerHTML;
            }
        }

        setResultText(result);
    }, []);



    return (
        <>
            {title && (
                <div className="modal__title">
                    {parse(title)}
                </div>
            )}
            {subtitle && (
                <div className="modal__subtitle">
                    <span>{subtitle}</span>
                </div>
            )}
            <div className="modal__txt">
                {parse(resultText)}
            </div>
        </>
    );
}

export default Content;
